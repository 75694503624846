<template>
    <div class="flex column center" style="margin-bottom:4rem;">
        <div class="new_box">
            <div class="titlee">微量分析，证实自己清白！</div>
            <p class="time">2019-04-24</p>
            
            <p class="titlee2" >案件经过：</p>
            <p class="space_content">
                某市的路口发生一起交通事故，经警方现场初步侦查，疑似林某骑驶地自行车与王某驾驶地货车发生刮擦，林某倒地受伤，后经医院抢救无效死亡。货车司机王某表示自行车在经过货车时忽然翻车倒地，自己并未碰撞到林某的自行车。警方为了查明相关事实，特委托福建历思司法鉴定所对此事故进行微量物证鉴定，即对货车上附着物质与自行车手把上刮痕物质进行物质同一性比对。
            </p>
            <div class="col8" style="margin:0 auto">
                <img class="col8" v-lazy="require('../../assets/case/3-1.png')">
            </div>
            <p class="titlee2" >鉴定分析：</p>
            <p class="space_content">
                福建历思司法鉴定所接到警方的委托，立即前往事故车辆停车场，对停放在此的货车、两轮自行车进行相关痕迹勘验、提取。
            </p>
            <p class="space_content">
                对货车进行勘验，并对其右后保险杠离断面的附着物进行提取，并标识为“检材1”。对两轮自行车左侧手把刮擦痕迹部位的表层物质进行提取，并标识为“检材2”。运用傅里叶变换红外光谱仪（FTIR）等仪器进行检测鉴定。
            </p>
            <p class="space_content">
                参照GB/T 19267.1-2008《刑事技术微量物证的理化检验 第1部分：红外吸收光谱法》进行检测，得出检材1（红线）和检材2（蓝线）的检测结果图像：
            </p>
            <div class="col8" style="margin:0 auto">
                <img class="col8" v-lazy="require('../../assets/case/3-2.png')">
            </div>
            <p class="titlee2" >鉴定结果：</p>
            <p class="space_content">
                当分子收到红外光的辐射，产生振动能级的跃迁，在振动时伴有偶极矩改变者就红外吸收光，形成红外吸收光谱。由于物质的组成不同，对红外吸收也不同，即不同物质有不同的红外光谱，这是定性依据。
            </p>
            <p class="space_content">
                “检材1”与“检材2”经红外光谱检验，检见二者比对图谱特征区和指纹区吸收峰不一致，且“检材1”无红外吸收峰，“检材1”与“检材2”不是相同物质。也就是说，货车上附着物质与自行车手把上刮痕物质不是同一物质。
            </p>
            <p class="space_content">
                警方根据福建历思司法鉴定所的鉴定结果，再综合其他的证据，排除了王某的货车与林某的自行车发生刮擦事故。而导致林某死亡的原因还需进一步调查，后经警方与死者家属进行沟通，对林某的遗体进行尸检，最终经法医鉴定林某为突发冠心病猝死。
            </p>
            <p class="titlee2" >微量物证在在交通事故中的作用：</p>
            <p class="space_content">
                微量物证是一门新兴的科学技术，广泛应用于刑事侦查。微量物证鉴定是运用物理学、 化学和仪器分析等方法，通过对有关物质材料的成分及其结构进行定性、定量分析，对检材 的种类、检材和嫌疑样本的同类性和同一性进行鉴定。
            </p>
            <p class="space_content">
                交通事故微量物证是指发生交通事故后，遗留或附着在事故现场中的道路、车辆、人体、衣着等相接触物体上或者在嫌疑车辆上的与事故案件相关的微量物质。
            </p>
            <p class="space_content">
                近年来，随着机动车辆的与日俱增，交通事故的频率也逐步上升。在新型的交通事故中，传统的物证不足以为处理案件提供足够的有效证据。因此，在交通事故处理中利用微量物证就十分重要了。
            </p>
            <p class="space_content">
                微量物证以其物小细微、难以发现、不易提取等特点对事故的现场提出了较高的要求。但对于还原事故现场、判定事故责任和侦破交通肇事逃逸案件中起着至关重要的作用。它已成为警方解决交通肇事疑难问题的重要手段，是认定和排除肇事嫌疑车辆的重要依据。
            </p>
            <p class="space_content">
                福建历思司法鉴定所成立于2006年4月，拥有12大类司法鉴定执业资质，是目前国内执业项目齐全的综合性司法鉴定机构，可对金属、木材、涂料、泥土、炸药、油墨、化妆品、 矿物，以及案件/交通事故/火灾现场遗留物等各种物质的成分与来源等进行微量物证鉴定。
            </p>
        </div>
        <div class="flex wrap col442" style="width:100%;text-align:left;margin-top:1rem">
            <div style="margin-bottom:1rem" class="col4 flex  start1">
                <div class="flex" style="margin-right:10%">
                    <p style="white-space:nowrap">上一篇：</p>无
                    
                </div>
            </div>
            <div style="margin-bottom:1rem" class="col4 flex  start1">
                <div class="flex">
                    <p style="white-space:nowrap">下一篇：</p>无
                    
                </div>
            </div>
            <div class="col2 flex  end1 center">
                <router-link class="a" style="color:#fff" :to="'/case/mic_case'"><el-button type="primary">返回列表</el-button></router-link >
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return { 
            data:{

            }
        }
    },
}
</script>
<style scoped>
.space_content{
    margin-bottom:2rem;
    
}
</style>